// Why import Turbo instead of StreamActions directly?
// -> [https://github.com/hotwired/turbo-rails/issues/441]
import { Turbo } from "@hotwired/turbo-rails";

Turbo.StreamActions.animate_with_class = function () {
  const className = this.getAttribute("class");
  const targetId = this.getAttribute("target");
  const target = document.getElementById(targetId);

  target.classList.remove(className);
  window.requestAnimationFrame(() => {
    target.classList.add(className);
  });
};
